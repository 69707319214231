<template>
  <div class="downloadPDF">
    <van-popup v-model:show="downloadshow">
      <div class="open">请使用其它浏览器打开此页面</div>
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      downloadshow: true
    };
  },
  created() {
    let pdf_src = this.$route.query.src;
    let ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      this.downloadshow = true;
      return true; // '微信浏览器'
    }
    window.location.href = pdf_src;
    return false; // 其他浏览器
  }
};
</script>

<style scoped="scoped" lang="scss">
/deep/ .van-popup {
  background-color: transparent;
}
.open {
  font-size: 28px;
  color: #ffffff;
}
</style>
